import React, { Fragment, useState, useEffect } from "react";
import { Seo, MenuNavbar, Footer } from "../components";
import { Header, About, BoardProduct, InterViews } from "../containers";
import useGetPage from "../hooks/useGetPage";
// import useStrAPI from "../hooks/useStrAPI";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ModalCard from "../components/ModalCard/ModalCard";
import { Height } from "@mui/icons-material";
import { height, width } from "@mui/system";
const qs = require("qs");

const HomeOne = () => {
  // เมื่อ Modal ถูกแสดง

  const [lang, setLang] = useState();
  const { t, i18n } = useTranslation();
  const { pages, loading, getPage } = useGetPage();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const { GetDatas, getStrAPI } = useStrAPI();

  // console.log("GetDatas", GetDatas);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    border: "none !important",
  };

  // ## SLIDE ##
  const settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    pauseOnFocus: false,
  };
  // ## SLIDE ##

  // Set default when reload page.
  useEffect(() => {
    if (localStorage.getItem("ncl_lang")) {
      setLang(localStorage.getItem("ncl_lang"));
      i18n.changeLanguage(lang);
    } else {
      setLang("th");
    }

    if (lang !== undefined) {
      getPage("home", lang);
      // getStrAPI(
      //   "interviews",
      //   lang,
      //   "&populate=*&sort[0]=id:desc&pagination[pageSize]=4"
      // );
    }
  }, [lang]);

  const handleLang = (event, attrLang) => {
    let changeText = attrLang === "th" ? "en" : "th";
    localStorage.setItem("ncl_lang", changeText);
    setLang(localStorage.getItem("ncl_lang"));
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    // แสดง Modal ที่นี่
    function showModal() {
      handleOpen();
    }
    const modalLastShownTime = localStorage.getItem("modalLastShownTime");
    const currentTime = Date.now();
    // const time = 1000; // 1 วิ
    const time = 1000 * 60 * 60 * 24; // 1 วัน
    const lastShownTime = parseInt(modalLastShownTime, 10);

    if (
      !modalLastShownTime ||
      isNaN(lastShownTime) ||
      currentTime - lastShownTime > time
    ) {
      // แสดง Modal
      showModal();
      // เซ็ตค่าใน localStorage เพื่อบอกว่า Modal ได้ถูกแสดง
      localStorage.setItem("modalLastShownTime", currentTime.toString());
    }
  }, []);

  if (
    pages != null &&
    pages !== undefined
    // GetDatas != null &&
    // GetDatas !== undefined
  ) {
    return (
      <div id="home">
        <Fragment>
          <Seo
            title="Home"
            description={`ก่อตั้ง NCL INTER LOGISTICS (S) PTE. LTD ซึ่งเป็นบริษัทย่อย ของบริษัทฯ ตั้งอยู่ในประเทศ สิงคโปร์ เพื่อประกอบธุรกิจให้บริการจัดการระบบโลจิสติกส์ โดยมีทุนจดทะเบียน 500,000 เหรียญดอลลาร์สิงคโปร์ ทั้งนี้บริษัทฯ ถือหุ้นในสัดส่วน 100% ของทุนจดทะเบียนและชำระแล้ว`}
            name="nclthailand"
            type="articlec"
            keywords="nclthailand, บริการขนส่งทางเรือ, บริการขนส่งทางอากาศ"
          />

          <MenuNavbar>
            <div className="align-items-center icon-nav-custom">
              <img
                src="images/icon/icon-12.png"
                alt={"NCL Language " + lang}
                // height="30"
                className="narbar_link img-icon-nav"
                data-lang={lang}
                onClick={(event) =>
                  handleLang(
                    event.target,
                    event.target.attributes.getNamedItem("data-lang").value
                  )
                }
              />
              <span
                style={{ textTransform: "uppercase" }}
                className="pt-1 narbar_font"
                data-lang={lang}
                onClick={(event) =>
                  handleLang(
                    event.target,
                    event.target.attributes.getNamedItem("data-lang").value
                  )
                }
              >
                {lang}
              </span>
            </div>
          </MenuNavbar>

          {/* ยังไม่เปิดใช้งาน */}

          {/* <Modal open={open} onClose={handleClose}>
            <Box sx={style} className="homeModal">
              <Typography>
                <p
                  className="fs-2 m-0 "
                  style={{
                    cursor: "pointer",
                    color: "white",
                    position: "absolute",
                    top: "-7%",
                    right: "-7%",
                  }}
                  onClick={handleClose}
                >
                  ✖
                </p>
                <img src="/images/queen9coj2565.jpg" className="w-100" />
              </Typography>
            </Box>
          </Modal> */}

          <Header pagesResult={pages}></Header>
          <About pagesAbout={pages}></About>

          <div className="slider-container mx-auto mb-0">
            <Slider {...settings}>
              {pages?.data.attributes.board_product.map((element) => {
                return (
                  <BoardProduct
                    title={element.title}
                    details={element.details}
                    image={element.file}
                    id={element.id}
                    key={element.id}
                  />
                );
              })}
            </Slider>
          </div>

          {/* <div className="section sec-news pt-3">
            <div className="container">
              <div className="row align-items-center justify-content-center text-center pb-5">
                <div className="col-lg-6">
                  <h1
                    className="heading mb-3 aos-init aos-animate fw-bold"
                    data-aos="fade-up"
                  >
                    {t("str_interview_title")}
                  </h1>
                  <hr
                    style={{
                      height: "5px",
                      maxWidth: "20%",
                      margin: "0px auto",
                      color: "#bc6c3c",
                      opacity: 1,
                    }}
                  ></hr>
                </div>
              </div>
              <div className="row">
                {GetDatas?.data.map((element) => {
                  return (
                    <InterViews
                      data={element.attributes}
                      id={element.id}
                      key={element.id}
                    />
                  );
                })}
              </div>
              <div class="row">
                <div class="col-lg-12 text-center py-3">
                  <a
                    href="contact.html"
                    className="btn"
                    style={{ backgroundColor: "#e5e1e2", color: "#3e5d75" }}
                  >
                    {t("str_viewmore")}
                  </a>
                </div>
              </div>
            </div>
          </div> */}

          <Footer></Footer>
        </Fragment>
      </div>
    );
  }
};
export default HomeOne;
